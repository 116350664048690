const appBarHeightRem = 3.875

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 4,
    backgroundColor: 'primary.main',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    height: (theme) => theme.spacing(appBarHeightRem),
  },
  toolbar: {
    width: (theme) => `min(100%, ${theme.spacing(90)})`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pl: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
    pr: {
      xs: 0,
      md: 1,
      lg: 2,
    },
  },
  wrapLogo: {
    display: 'flex',
    alignItems: 'center',
    columnGap: {
      xs: 1,
      md: 1.5,
      lg: 2,
    },
  },
  logo: {
    display: 'flex',
    width: (theme) => ({
      xs: theme.spacing(3.75),
      md: 'auto',
    }),
    backgroundColor: 'transparent',

    '&.MuiButton-root': {
      p: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },

  menuList: {
    display: 'flex',
  },
  menuButton: {
    backgroundColor: 'transparent',
    columnGap: 0.5,
    py: {
      xs: 0.75,
      md: 0.75,
      lg: 1,
    },
    px: {
      xs: 1,
      md: 1.5,
    },

    '&:hover, &:focus-visible': {
      backgroundColor: 'transparent',
    },

    '& .MuiButton-endIcon': {
      ml: 0,
      '& :nth-of-type(1)': {
        fontSize: 16,
      },
    },
  },
  activeMenuButton: {
    fontWeight: 600,
  },
}

export default styles
