import PropTypes from 'prop-types'
import { Typography, Box, Button } from '@mui/material'

import { useTexts } from '../../../texts'
import * as Icons from '../../../styleguide/icons'

import styles from './personalDataCard.styles'

const personalDataCardPropTypes = {
  userData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    familyName: PropTypes.string,
    phone: PropTypes.string,
    communicationsAccepted: PropTypes.bool,
  }),
  onClickUpdatePersonalData: PropTypes.func,
}

const PersonalDataCard = ({ userData, onClickUpdatePersonalData }) => {
  const texts = useTexts()

  const TEXT_BY_COMMUNICATION_ACEPTANCE = {
    true: {
      icon: <Icons.Check color='#75D7A6' />,
      text: texts.communicationsAgreementAcepted(),
    },
    false: {
      icon: <Icons.Close color='#E76245' />,
      text: texts.communicationsAgreementNotAcepted(),
    },
  }

  const communicationsAccepted =
    TEXT_BY_COMMUNICATION_ACEPTANCE[userData.communicationsAccepted]

  return (
    <Box sx={styles.card}>
      <Box sx={styles.mainData}>
        <Typography sx={styles.bold}>
          {`${userData.name} ${userData.familyName}`}
        </Typography>
        <Typography>{userData.email}</Typography>
        <Typography>{userData.phone}</Typography>
      </Box>
      <Box sx={styles.caption}>
        {communicationsAccepted.icon}
        <Typography>{communicationsAccepted.text}</Typography>
      </Box>
      <Button
        id='dashboard-top-up-btn'
        fullWidth
        color='secondary'
        startIcon={<Icons.User1 />}
        sx={styles.cta}
        onClick={onClickUpdatePersonalData}
      >
        {texts.updatePersonalDataAction()}
      </Button>
    </Box>
  )
}

PersonalDataCard.propTypes = personalDataCardPropTypes

export default PersonalDataCard
