const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.5,
  },
  price: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 0.5,
    p: 1,
    color: 'neutral.dark',
    borderRadius: (theme) => theme.spacing(1.25),
    boxShadow: 1,
    backgroundColor: 'neutral.white',
    position: 'relative',
    zIndex: 1,
  },
  priceAmount: {
    display: 'inline-flex',
    alignItems: 'baseline',
    fontFamily: 'ClashDisplay-Semibold',
    fontSize: (theme) => ({
      xs: theme.spacing(1.5),
      md: theme.spacing(1.5),
    }),
    fontWeight: 600,
    color: 'neutral.dark',

    '& > span': {
      display: 'block',
      fontSize: (theme) => theme.spacing(1),
      fontWeight: 500,
      letterSpacing: '0.02em',
      textShadow: '0.5px 0.5px 1px rgba(255, 255, 255, 0.08)',
      color: 'primary.dark',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
    position: 'relative',
  },
  descriptionTitle: {
    color: 'neutral.black',
  },
  descriptionText: {
    color: 'neutral.main',
  },
  bg: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    color: 'neutral.black',
    position: 'relative',
    backgroundColor: 'neutral.lightest',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: (theme) => theme.spacing(-3),
      left: 'calc(50% - 50vw)',
      zIndex: 0,
      backgroundColor: 'inherit',
    },
  },
}

export default styles
