import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Box, IconButton, Toolbar, Typography } from '@mui/material'

import { ChevronLeft } from '../../styleguide/icons'
import { useTexts } from '../../texts'
import { User, Public } from '../../business'
import ScrollRevisionOnPageChange from '../ScrollRevisionOnPageChange'

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    height: 3.375,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: 'neutral.white',
    p: 0,
    ' & button': {
      m: 1,
    },
  },
  main: {
    mt: 3.375,
    flexGrow: 2,
  },
}

const TITLE_PATH_MAP = {
  'top-up-balance': (texts) => texts.getBalanceTopUpLabel(),
  'bank-card-edition': (texts) => texts.getBankCardEditLabel(),
  'bank-card-introduction': (texts) => texts.getBankCardAddLabel(),
  'update-personal-data': (texts) => texts.updatePersonalData(),
  'update-password': (texts) => texts.updatePassword(),
}

const SecondaryPageLayoutView = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const texts = useTexts()
  const sections = Public.useArticlesSections()

  const [title, setTitle] = useState('')

  const handleGoBack = () => {
    navigate(`/${location.pathname.split('/')[1]}`)
  }

  useEffect(() => {
    setTitle(() => {
      if (location.pathname.includes('section')) {
        if (!!sections?.data) {
          return sections.data[params.sectionId]
        }
      } else {
        const pathName = Object.keys(TITLE_PATH_MAP).find((path) =>
          location.pathname.includes(path),
        )
        if (pathName) {
          return TITLE_PATH_MAP[pathName](texts)
        }
      }
      return ''
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, location])

  return (
    <ScrollRevisionOnPageChange>
      <Box sx={styles.root}>
        <Toolbar disableGutters sx={styles.appBar}>
          <IconButton aria-label='volver atrás' onClick={handleGoBack}>
            <ChevronLeft />
          </IconButton>
          <Typography component='h2' variant='h4'>
            {title}
          </Typography>
        </Toolbar>
        <Box component='main' sx={styles.main}>
          {children}
        </Box>
      </Box>
    </ScrollRevisionOnPageChange>
  )
}

const SecondaryPageLayout = ({ children }) => {
  return (
    <User.PersonalDataProvider>
      <User.BalanceProvider>
        <User.BankCardProvider>
          <SecondaryPageLayoutView>{children}</SecondaryPageLayoutView>
        </User.BankCardProvider>
      </User.BalanceProvider>
    </User.PersonalDataProvider>
  )
}

export default SecondaryPageLayout
