import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  InputAdornment,
  Box,
  Paper,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import {
  Alert,
  FormSkinWrapper,
  TextField,
  Checkbox,
} from '../../../components/index.js'
import * as Icons from '../../../../styleguide/icons'
import { useTexts } from '../../../../texts/index.js'
import styles from './updatePersonalData.styles.js'

const updatePersonalDataViewPropTypes = {
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onChangeControlledLegalCheckbox: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    phone: PropTypes.string,
    communicationsAccepted: PropTypes.string,
  }).isRequired,
  formError: PropTypes.string.isRequired,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
  submitSucceed: PropTypes.bool,
}

const UpdatePersonalDataView = ({
  formId,
  onSubmit,
  isSubmitting,
  submitSucceed,
  onChangeControlledLegalCheckbox,
  formValues,
  formError,
  fieldErrors,
}) => {
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <Paper sx={styles.content}>
        <Typography component='h1' sx={styles.title}>
          {texts.updatePersonalDataTitle()}
        </Typography>
        <form id={formId} onSubmit={onSubmit} noValidate>
          <FormSkinWrapper skin='light' sx={styles.wrapFields}>
            <Box sx={styles.groupFields}>
              <TextField
                id='name'
                name='name'
                defaultValue={formValues.name}
                label={texts.getNameLabel()}
                autoComplete='given-name'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icons.User1 />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(fieldErrors.name)}
                required
              />
              <TextField
                id='familyName'
                name='familyName'
                defaultValue={formValues.familyName}
                label={texts.getFamilyNameLabel()}
                autoComplete='family-name'
                error={Boolean(fieldErrors.familyName)}
                required
              />
              {fieldErrors.name || fieldErrors.familyName ? (
                <Box sx={styles.groupErrors}>
                  <Icons.ExclamationCircle />
                  {fieldErrors.name || fieldErrors.familyName}
                </Box>
              ) : null}
            </Box>
            <TextField
              id='phone'
              name='phone'
              defaultValue={formValues.phone}
              label={texts.getPhoneLabel()}
              autoComplete='tel'
              type='tel'
              helperText={fieldErrors.phone}
              error={Boolean(fieldErrors.phone)}
            />
            <Box id='legal-checks'>
              <Checkbox
                label={texts.getLegalAgreementCommunicationsLabel()}
                name='communicationsAccepted'
                checked={Boolean(formValues.communicationsAccepted)}
                onChange={onChangeControlledLegalCheckbox}
              />
            </Box>
            <Box sx={styles.cta}>
              {Boolean(formError) ? (
                <Alert variant='error' text={formError} />
              ) : null}

              {submitSucceed && (
                <Alert variant='success' text={texts.getSaveSucceed()} />
              )}

              <LoadingButton
                type='submit'
                color='secondary'
                loading={isSubmitting}
                disabled={isSubmitting}
                loadingIndicator={<CircularProgress size={16} />}
                fullWidth
              >
                {texts.updatePersonalDataAction()}
              </LoadingButton>
            </Box>
          </FormSkinWrapper>
        </form>
      </Paper>
    </Box>
  )
}

UpdatePersonalDataView.propTypes = updatePersonalDataViewPropTypes

export default UpdatePersonalDataView
