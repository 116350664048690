import {
  nativeApps,
  payperMagic,
  chromeExtension,
} from '../thirdPartyIntegration'

const BROWSER_NAMES = {
  BRAVE: 'Brave',
  FIREFOX: 'Firefox',
  OPERA: 'Opera',
  CHROME: 'Chrome',
  SAFARI: 'Safari',
  EDGE: 'Edge',
  IE: 'Internet Explorer',
}

const getBrowserName = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('firefox') > -1) {
    return BROWSER_NAMES.FIREFOX
  } else if (userAgent.indexOf('opr') > -1 || userAgent.indexOf('opera') > -1) {
    return BROWSER_NAMES.OPERA
  } else if (userAgent.includes('chrome') && navigator.brave !== undefined) {
    return BROWSER_NAMES.BRAVE
  } else if (
    userAgent.indexOf('chrome') > -1 &&
    userAgent.indexOf('edg') === -1 &&
    userAgent.indexOf('opr') === -1
  ) {
    return BROWSER_NAMES.CHROME
  } else if (
    userAgent.indexOf('safari') > -1 &&
    userAgent.indexOf('chrome') === -1
  ) {
    return BROWSER_NAMES.SAFARI
  } else if (userAgent.indexOf('edg') > -1) {
    return BROWSER_NAMES.EDGE
  } else if (userAgent.indexOf('trident') > -1) {
    return BROWSER_NAMES.IE
  } else {
    return undefined
  }
}

// Function to detect if the user is on an desktop device
const isDesktop = () =>
  !/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    navigator.userAgent.toLowerCase(),
  )

// Function to detect if the user is on an Android device
const isAndroid = () => /Android/i.test(navigator.userAgent)

// Function to detect if the user is on an iOS device
export const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent)

// Function to detect if the user is using an in-app browser
const isInAppBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  return (
    ua.includes('FBAN') ||
    ua.includes('FBAV') || // Facebook
    ua.includes('Instagram') || // Instagram
    ua.includes('Line') || // Line
    ua.includes('Twitter') || // Twitter
    ua.includes('Snapchat') || // Snapchat
    ua.includes('LinkedIn') // LinkedIn
  )
}

export const browser = {
  name: getBrowserName,
  isChrome: () => getBrowserName() === BROWSER_NAMES.CHROME,
  isEdge: () => getBrowserName() === BROWSER_NAMES.EDGE,
  isDesktop,
  isAndroid,
  isIOS,
  isInAppBrowser,
}

let windowObjectReference = null
export const openExternalLink = (url) => {
  if (windowObjectReference === null || windowObjectReference.closed) {
    const target = nativeApps.isEmbeddedInNativeApp() ? '_self' : '_blank'
    windowObjectReference = window.open(url, target, 'noreferrer,noopener')
  } else {
    windowObjectReference.focus()
  }
}

export const openMediaLink = async (url) => {
  const isChromeExtensionAvailable = await chromeExtension.getAvailability()
  if (nativeApps.isEmbeddedInNativeApp() || isChromeExtensionAvailable) {
    openExternalLink(url)
  } else {
    payperMagic.redirect(url)
  }
}

export class DocumentCookies {
  static #instance = null
  #values = {}

  constructor() {
    if (DocumentCookies.instance) {
      return DocumentCookies.#instance
    }
    DocumentCookies.#instance = this
    this.refresh()
  }

  refresh() {
    this.#values = document.cookie.split('; ').reduce((temp, cookie) => {
      const [key, value] = cookie.split('=')
      return { ...temp, [key]: value }
    }, {})
  }

  get(key) {
    return this.#values[key]
  }
}
