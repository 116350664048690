import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { Auth, Session, URL_SEARCH_PARAMS_AUTH_KEYS } from '../../../business'
import { CircularProgress } from '../../components'
import useSearchParams from './useSearchParams'
import useAccessFinish from './useAccessFinish'

const getAuthCodeFromUrlParam = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get(URL_SEARCH_PARAMS_AUTH_KEYS.THIRD_PARTY_CODE)
}

const dispatchAnalyticsSuccessEvent = (
  identityProvider,
  hasSystemUser,
  userSub,
) => {
  let name = ''
  if (identityProvider === Auth.AUTHORIZE_IDENTITY_PROVIDER.APPLE) {
    name = hasSystemUser
      ? EVENTS_NAMES.PA_FORM_SUBMIT_LOGIN_APPLE
      : EVENTS_NAMES.PA_FORM_SUBMIT_SIGNUP_APPLE
  } else if (identityProvider === Auth.AUTHORIZE_IDENTITY_PROVIDER.GOOGLE) {
    name = hasSystemUser
      ? EVENTS_NAMES.PA_FORM_SUBMIT_LOGIN_GOOGLE
      : EVENTS_NAMES.PA_FORM_SUBMIT_SIGNUP_GOOGLE
  }

  document.dispatchEvent(
    new CustomEvent('payperAnalyticsPublicEvent', {
      detail: {
        name,
        userSub,
        status: 'success',
      },
    }),
  )
}

const SuccessThirdPartyIdpAccess = () => {
  const navigate = useNavigate()

  const {
    state: { identityProvider },
  } = useLocation()

  const paramsData = useSearchParams()
  const accessFinish = useAccessFinish(paramsData)

  useEffect(() => {
    ;(async () => {
      if (Boolean(paramsData)) {
        const code = getAuthCodeFromUrlParam()
        const tokens = await Auth.getJWTs(code)

        const { hasSystemUser } = await Auth.verifyCompleteRegistration(
          tokens.accessToken,
        )
        if (!hasSystemUser) {
          if (!paramsData?.isReferred) {
            await Auth.removeIdpAccountInCognito(tokens)
            navigate('/access', {
              state: { referredRegistrationError: true },
            })
            return
          } else {
            await Auth.registerIdpAccountInTransactionalDB(
              tokens,
              paramsData?.attribution,
            )
          }
        }

        Session.storeSession(tokens)

        dispatchAnalyticsSuccessEvent(
          identityProvider,
          hasSystemUser,
          Session.getUserSub(),
        )

        accessFinish.handleFinishAuth({ isNewUser: !hasSystemUser })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsData])

  return <CircularProgress height='80vh' />
}

export default SuccessThirdPartyIdpAccess
