import PropTypes from 'prop-types'

import { CircularProgress } from '../../../components'
import useAccess from './useAccess'
import Modal from './Modal'
import Steps from './Steps'

export const variantPropType = PropTypes.oneOf([
  { name: 'STANDARD' },
  { name: 'PROMOTION', partnerName: PropTypes.string },
  { name: 'PARTNER_MANDATORY_WARNING' },
  { name: 'PARTNER_REGISTRATION_ERROR' },
])

const Access = () => {
  const {
    unauthenticationIsConfirmedInLoad,
    handleCloseButton,
    ...stepsProps
  } = useAccess()

  if (!Boolean(unauthenticationIsConfirmedInLoad))
    return <CircularProgress height='80vh' />

  return (
    <Modal onCancel={handleCloseButton}>
      <Steps {...stepsProps} />
    </Modal>
  )
}

export default Access
