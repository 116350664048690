import { useState } from 'react'
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import { useTexts } from '../../../../texts'
import * as Icons from '../../../../styleguide/icons'
import styles from './search.styles'

const SEARCH_BAR_NODE_ID = 'search-bar'

const SearchView = ({
  initialInputValue,
  onCancelSearch,
  onSubmitSearch,
  renderResults,
}) => {
  const texts = useTexts()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const [searchInputValue, setSearchInputValue] = useState(initialInputValue)

  return (
    <Box sx={styles.root}>
      <Box sx={!widerThanTablet ? styles.fixed : {}} id={SEARCH_BAR_NODE_ID}>
        <Box component='form' sx={styles.searchBar} onSubmit={onSubmitSearch}>
          <IconButton aria-label='volver atrás' onClick={onCancelSearch}>
            <Icons.ChevronLeft />
          </IconButton>

          <TextField
            id='search'
            name='search'
            value={searchInputValue}
            onChangeCapture={(e) => setSearchInputValue(e.target.value)}
            fullWidth
            placeholder={texts.searchPlaceholder()}
            InputProps={{
              startAdornment: widerThanTablet && (
                <InputAdornment position='start'>
                  <Icons.Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setSearchInputValue('')}
                  >
                    <Icons.Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {widerThanTablet ? (
            <Button size='small' color='primary' type='submit'>
              {texts.searchAction()}
            </Button>
          ) : (
            <IconButton aria-label='buscar' type='submit'>
              <Icons.Search />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={styles.results}>{renderResults()}</Box>
    </Box>
  )
}

export default SearchView
