import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { ExclamationCircle } from '../../../styleguide/icons'
import styles from './helperText.styles'

const helperTextPropTypes = {
  text: PropTypes.string.isRequired,
}

const HelperText = ({ text }) => (
  <Box sx={styles.root}>
    <Box sx={styles.icon}>
      <ExclamationCircle />
    </Box>
    <Typography component='span' sx={styles.text}>
      {text}
    </Typography>
  </Box>
)

HelperText.propTypes = helperTextPropTypes

export default HelperText
