import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../../texts'

import {
  BankCardWithEditionButton,
  LogoutDialog,
  BalanceCard,
  BonusCard,
} from '../../../components'
import styles from './account.styles'
import PersonalData from './PersonalData'

const Section = ({ title, children }) => {
  return (
    <Box>
      <Typography component='h2' variant='h4'>
        {title}
      </Typography>
      <Box sx={styles.sectionContent}>{children}</Box>
    </Box>
  )
}

const AccountPropTypes = {
  onClickTopUp: PropTypes.func,
  onClickEditBankCard: PropTypes.func,
  onClickAddBankCard: PropTypes.func,
  onClickUpdatePersonalData: PropTypes.func,
  onClickUpdatePassword: PropTypes.func,
}

const Account = ({
  bankCard,
  balance,
  userData,
  bonus,
  onClickTopUp,
  onClickEditBankCard,
  onClickAddBankCard,
  onClickUpdatePersonalData,
  onClickUpdatePassword,
}) => {
  const texts = useTexts()

  const [showLogoutDialog, setShowLogoutDialog] = useState(false)

  return (
    <>
      <LogoutDialog
        isOpen={showLogoutDialog}
        onClose={() => setShowLogoutDialog(false)}
      />
      <Box sx={styles.root}>
        <Section title={texts.balanceTopUp()}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: texts.balancePayperPolicy(),
            }}
          />
          {!balance.isLoading && (
            <BalanceCard
              withTopUp={!(bankCard.isLoading || !bankCard.data)}
              balanceData={balance.data}
              onClickTopUp={onClickTopUp}
            />
          )}
        </Section>
        <Section title={texts.balanceBonus()}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: texts.balancePayperBonusPolicy(),
            }}
          />
          {!(bonus.isLoading || bonus.error || !Boolean(bonus.data)) && (
            <BonusCard bonusData={bonus.data} />
          )}
        </Section>

        {!bankCard.isLoading && (
          <Section title={texts.bankCard()}>
            <BankCardWithEditionButton
              bankCardData={bankCard.data}
              onClickEditBankCard={onClickEditBankCard}
              onClickAddBankCard={onClickAddBankCard}
            />
          </Section>
        )}

        {Boolean(userData) && (
          <Section title={texts.personalData()}>
            <PersonalData
              userData={userData}
              onClickUpdatePassword={onClickUpdatePassword}
              onClickUpdatePersonalData={onClickUpdatePersonalData}
              onClickLogout={() => setShowLogoutDialog(true)}
            />
          </Section>
        )}
      </Box>
    </>
  )
}

Account.propTypes = AccountPropTypes

export default Account
