import baseTheme from '../base'

const MuiTabs = {
  styleOverrides: {
    root: {
      '& .MuiTabs-indicator': {
        backgroundColor: baseTheme.palette.neutral.black,
      },
    },
  },
}

export default MuiTabs
