import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  InputAdornment,
  Link as MUILink,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { useTexts } from '../../../../texts'
import useIntersectionObserver from '../../../useIntersectionObserver'
import {
  FormSkinWrapper,
  Picture,
  TextField,
  Checkbox,
} from '../../../components'
import * as Icons from '../../../../styleguide/icons'
import imageAvif from './young-man-in-formalwear-sitting-on-the-floor-with-phone.avif'
import imageWebp from './young-man-in-formalwear-sitting-on-the-floor-with-phone.webp'
import imagePng from './young-man-in-formalwear-sitting-on-the-floor-with-phone.png'
import styles from './footer.styles'

const contactFormProptypes = {
  formId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  fieldErrors: PropTypes.shape({
    name: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
    conditionsAccepted: PropTypes.string,
    communicationsAccepted: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
}

const ContactFormView = ({ formId, isSubmitting, fieldErrors, onSubmit }) => {
  const texts = useTexts()

  const targets = useRef(new Set())
  const [entries, setObservedNodes] = useIntersectionObserver({})

  useEffect(() => {
    setObservedNodes(() => [...targets.current])
  }, [setObservedNodes])

  useEffect(() => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        entry.target.classList.add('isVisible')

        setObservedNodes((observedNodes) =>
          observedNodes.filter((node) => node !== entry.target),
        )
      }
    }
  }, [entries, setObservedNodes])

  return (
    <Box component='section' sx={styles.root}>
      <Container>
        <Box sx={styles.content}>
          <Box sx={styles.info}>
            <Box
              ref={(element) => targets.current.add(element)}
              sx={styles.wrapText}
            >
              <Typography variant='h2' sx={styles.heading}>
                {texts.getContactFormTitleLabel()}
              </Typography>
              <Typography sx={styles.description}>
                {texts.getContactFormDescriptionLabel()}
              </Typography>
            </Box>
            <Box
              ref={(element) => targets.current.add(element)}
              sx={styles.wrapPicture}
              style={{ '--delay': '300ms' }}
            >
              <Picture
                width={536}
                height={446}
                avif={imageAvif}
                webp={imageWebp}
                fallback={imagePng}
                loading='lazy'
              />
            </Box>
          </Box>
          <Box
            ref={(element) => targets.current.add(element)}
            sx={styles.slot}
            style={{ '--delay': '250ms' }}
          >
            <form id={formId} onSubmit={onSubmit} noValidate>
              <FormSkinWrapper skin='dark' sx={styles.wrapFields}>
                <Box sx={styles.groupFields}>
                  {/*
                    NOTE: According to UI specs, we handle both errors in one
                    place, so get rid of the built-in prop `helperText` the `TextField`
                    component provides.
                   */}
                  <TextField
                    id='contactFormName'
                    name='name'
                    label={texts.getContactFormNameLabel()}
                    autoComplete='given-name'
                    error={Boolean(fieldErrors.name)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Icons.User1 />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                  <TextField
                    id='contactFormFamilyName'
                    name='familyName'
                    label={texts.getContactFormSurnameLabel()}
                    autoComplete='family-name'
                    error={Boolean(fieldErrors.familyName)}
                    Icon={null}
                    required
                  />
                  {fieldErrors.name || fieldErrors.familyName ? (
                    <Box sx={styles.groupErrors}>
                      <Icons.ExclamationTriangle />
                      {fieldErrors.name || fieldErrors.familyName}
                    </Box>
                  ) : null}
                </Box>
                <TextField
                  id='contactFormEmail'
                  name='email'
                  label={texts.getContactFormEmailLabel()}
                  type='email'
                  autoComplete='email'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icons.Envelope />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(fieldErrors.email)}
                  helperText={fieldErrors.email}
                  inputProps={{ inputMode: 'email' }}
                  required
                />
                <TextField
                  id='contactFormMessage'
                  name='message'
                  label={texts.getContactFormMessageLabel()}
                  multiline
                  rows={6}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Icons.ChatTeardropText />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(fieldErrors.message)}
                  helperText={fieldErrors.message}
                  required
                />
                <Checkbox
                  skin='dark'
                  label={
                    <>
                      {texts.getLegalAgreementLabel1()}
                      <MUILink
                        href='https://www.bepayper.com/terminos-y-condiciones'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {texts.getLegalAgreementTermsLabel()}
                      </MUILink>
                      {texts.getAndLabel()}
                      <MUILink
                        href='https://www.bepayper.com/politica-de-privacidad'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {texts.getLegalAgreementPrivacyPolicyLabel()}
                      </MUILink>
                      {texts.getLegalAgreementLabel2()}
                    </>
                  }
                  name='conditionsAccepted'
                  error={
                    fieldErrors.conditionsAccepted &&
                    texts.getErrorConditionsAccepted()
                  }
                  required
                />
                <Checkbox
                  sx={{ mt: -0.5 }}
                  skin='dark'
                  label={texts.getLegalAgreementCommunicationsLabel()}
                  name='communicationsAccepted'
                  error={fieldErrors.communicationsAccepted}
                />

                <LoadingButton
                  type='submit'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  endIcon={<Icons.ArrowRight />}
                  loadingIndicator={<CircularProgress size={16} />}
                >
                  {texts.getContactFormSendAction()}
                </LoadingButton>
              </FormSkinWrapper>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

ContactFormView.propTypes = contactFormProptypes

export default ContactFormView
