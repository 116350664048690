import PropTypes from 'prop-types'
import { Box, Button, MenuItem } from '@mui/material'

import * as PT from '../../propTypes'
import * as Icons from '../../../styleguide/icons'
import styles from './appBarNavigation.styles'

const APP_BAR_NAVIGATION_ID = 'app-bar-navigation'

const AppBarNavigationPropTypes = {
  actions: PT.NavigationActions.isRequired,
  onClickMenuItem: PropTypes.func.isRequired,
  onClickHome: PropTypes.func.isRequired,
  activeAction: PT.NavigationItemName,
}

const AppBarNavigation = ({
  actions,
  onClickMenuItem,
  onClickHome,
  activeAction,
}) => {
  const accountActionIndex = actions.findIndex(
    (action) => action.name === 'account',
  )
  const accountAction =
    accountActionIndex !== -1 ? actions[accountActionIndex] : null
  const menuActions =
    accountActionIndex !== -1
      ? actions
          .slice(0, accountActionIndex)
          .concat(actions.slice(accountActionIndex + 1))
      : actions

  const getActionButtonStyles = (action) => {
    const isActionActive = action.name === activeAction
    return {
      ...styles.menuButton,
      ...(isActionActive ? styles.activeMenuButton : {}),
    }
  }

  return (
    <Box component='header' sx={styles.root} id={APP_BAR_NAVIGATION_ID}>
      <Box sx={styles.toolbar}>
        <Box sx={styles.wrapLogo}>
          <Button sx={styles.logo} onClick={onClickHome}>
            <Icons.PayperLogo aria-label='Payper logo' color='#121212' />
          </Button>
        </Box>
        <Box sx={styles.menuList}>
          <Box sx={styles.menuList}>
            {menuActions.map((item) => (
              <MenuItem
                key={item.path}
                sx={getActionButtonStyles(item)}
                onClick={() => onClickMenuItem(item)}
              >
                {item.label}
              </MenuItem>
            ))}
          </Box>

          {Boolean(accountAction) && (
            <Button
              id='account-button'
              endIcon={accountAction.icon}
              aria-label='user-button'
              role='menuitem'
              sx={getActionButtonStyles(accountAction)}
              onClick={() => onClickMenuItem(accountAction)}
            >
              {accountAction.label}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

AppBarNavigation.propTypes = AppBarNavigationPropTypes

export default AppBarNavigation
