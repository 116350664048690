import baseTheme from '../base'

const MuiTab = {
  styleOverrides: {
    root: {
      ...baseTheme.typography.h6,
      fontWeight: 400,
      textTransform: 'none',
      color: baseTheme.palette.neutral.black,
      margin: 'max(2px, 0.08em)',
      '&:hover': {
        color: baseTheme.palette.primary.main,
      },
      '&.Mui-selected': {
        color: baseTheme.palette.neutral.black,
        fontWeight: 600,
      },
      '&.Mui-focusVisible': {
        outline: `max(2px, 0.08em) solid ${baseTheme.palette.primary.main}`,
      },
    },
  },
}

export default MuiTab
