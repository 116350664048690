import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import { AssociatedMediaData } from '../../propTypes'
import { MediaButton } from '..'
import styles from './associatedMedia.styles'

const associatedMediaPropTypes = {
  medias: PropTypes.arrayOf(AssociatedMediaData),
  isPublicVersion: PropTypes.bool,
  onClickMedia: PropTypes.func,
}

const AssociatedMedia = ({ medias = [], isPublicVersion, onClickMedia }) => {
  const texts = useTexts()

  return (
    <>
      <Box sx={styles.root}>
        {isPublicVersion ? (
          <Typography component='h2' variant='h4'>
            {texts.getAssociatedMediaPublicTitle()}
          </Typography>
        ) : (
          <Typography variant='h6'>
            {texts.getAssociatedMediaTitle()}
          </Typography>
        )}
        {!isPublicVersion && (
          <Typography variant='subtitle2'>
            {texts.getAssociatedMediaSubtitle()}
          </Typography>
        )}
        {/* NOTE: Do not change the id 'associated_media'. It is referred from the Products-Fruits' onboarding. */}
        <Box sx={styles.logos} id='associated_media'>
          {medias.map((mediaData, i) => (
            <MediaButton
              key={mediaData?.id ?? i}
              onClick={onClickMedia}
              data={mediaData}
            />
          ))}
        </Box>
      </Box>
    </>
  )
}

AssociatedMedia.propTypes = associatedMediaPropTypes

export default AssociatedMedia
