import { useLayoutEffect } from 'react'
import { Box } from '@mui/material'

import { AssociatedMedia } from '../../components'
import EVENTS_NAMES from '../../../analytics/eventsNames.json'
import { openMediaLink } from '../../utils'
import { ASSOCIATED_MEDIAS_DATA } from '../../constants'

const styles = {
  appBar: {
    width: '100%',
    backgroundColor: 'primary.main',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    rowGap: {
      xs: 2.5,
      md: 3.5,
    },
    width: (theme) => `min(100%, ${theme.spacing(59.5)})`,
    mx: 'auto',
    pt: {
      xs: 2,
      md: 3,
    },
    pb: {
      xs: 4,
      lg: 6,
    },

    '& h5': {
      m: 0,
      py: (theme) => ({
        xs: theme.spacing(0.5),
        md: theme.spacing(1),
      }),
    },
  },
}

const Home = () => {
  const openMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPublicEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
        },
      }),
    )
    openMediaLink(mediaData.domain)
  }

  useLayoutEffect(() => {
    const appBarHeight = document
      .getElementById('public-header')
      ?.getBoundingClientRect()?.height

    if (Boolean(appBarHeight)) {
      document.getElementById('header-public-background').style.height =
        appBarHeight.toString() + 'px'
    }
  }, [])

  return (
    <Box component='main' aria-label='homeV2'>
      <Box sx={styles.appBar} id='header-public-background' />
      <Box sx={styles.root}>
        <AssociatedMedia
          medias={ASSOCIATED_MEDIAS_DATA}
          onClickMedia={openMediaPage}
          isPublicVersion
        />
      </Box>
    </Box>
  )
}

export default Home
