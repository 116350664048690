import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import { useTexts } from '../../../texts'
import * as Icons from '../../../styleguide/icons'
import styles from './socialLogin.styles'

const socialLoginButtonsViewPropTypes = {
  skin: PropTypes.oneOf(['dark', 'light']),
  appleHref: PropTypes.string.isRequired,
  googleHref: PropTypes.string.isRequired,
  onClickGoogle: PropTypes.func,
  onClickApple: PropTypes.func,
}

const SocialLoginButtonsView = ({
  skin = 'light',
  appleHref,
  googleHref,
  onClickGoogle = () => {},
  onClickApple = () => {},
  disabled,
}) => {
  const texts = useTexts()

  const buttonVariant = skin === 'dark' ? 'appleLight' : 'appleDark'

  return (
    <Box sx={styles.root}>
      <Button
        id='register-google-btn'
        variant='google'
        component='a'
        href={googleHref}
        startIcon={<Icons.LogoGoogleColor />}
        size='small'
        sx={styles.cta}
        onClick={onClickGoogle}
        disabled={disabled}
      >
        {texts.getUseGoogleAccountCTA()}
      </Button>
      <Button
        id='register-apple-btn'
        variant={buttonVariant}
        component='a'
        href={appleHref}
        startIcon={<Icons.LogoApple />}
        size='small'
        sx={styles.cta}
        onClick={onClickApple}
        disabled={disabled}
      >
        {texts.getUseAppleAccountCTA()}
      </Button>
    </Box>
  )
}

SocialLoginButtonsView.propTypes = socialLoginButtonsViewPropTypes

export default SocialLoginButtonsView
