const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    color: 'neutral.black',
    position: 'relative',
    backgroundColor: 'neutral.lightest',

    '&::before': {
      content: '""',
      display: 'block',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: (theme) => theme.spacing(-1.5),
      left: 'calc(50% - 50vw)',
      zIndex: 0,
      backgroundColor: 'inherit',
    },

    '& > form': {
      position: 'relative',
    },
  },
  form: {
    pt: 0.75,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0.5,
  },
  logos: {
    display: 'flex',
    justifyContent: 'center',
    filter: 'grayscale(1)',
    position: 'relative',
  },
}

export default styles
