import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { AssociatedMedia } from '../../../components'
import { openMediaLink } from '../../../utils'
import { ASSOCIATED_MEDIAS_DATA } from '../../../constants'
import Search from './Search'
import Sections from './Sections'

const Discovery = () => {
  const openMediaPage = (mediaData) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_CLICK_MEDIA,
          mediaId: mediaData.id,
        },
      }),
    )

    openMediaLink(mediaData.domain)
  }

  return (
    <>
      <Search />
      <AssociatedMedia
        medias={ASSOCIATED_MEDIAS_DATA}
        onClickMedia={openMediaPage}
        isPublicVersion
      />
      <Sections />
    </>
  )
}

export default Discovery
