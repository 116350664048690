import PropTypes from 'prop-types'
import { Button, Typography, Box } from '@mui/material'

import { formatNumber } from '../../format'
import { useTexts } from '../../../texts'
import * as Icons from '../../../styleguide/icons'
import styles from './balance.styles'

const balanceCardPropTypes = {
  withTopUp: PropTypes.bool,
  balanceData: PropTypes.string.isRequired,
  onClickTopUp: PropTypes.func,
}

const BalanceCard = ({ withTopUp, balanceData, onClickTopUp = () => {} }) => {
  const texts = useTexts()

  return (
    <Box sx={styles.card}>
      <Box sx={styles.info}>
        <Box sx={styles.top}>
          <Typography sx={styles.amount}>
            {formatNumber(balanceData)} <span>€</span>
          </Typography>
        </Box>
      </Box>
      {withTopUp ? (
        <Button
          id='dashboard-top-up-btn'
          fullWidth
          color='secondary'
          sx={styles.cta}
          onClick={onClickTopUp}
        >
          {texts.getBalanceTopUpAction()}
        </Button>
      ) : (
        <Box sx={styles.noCta}>
          <Icons.PayperLogo color='#FFCC00' />
        </Box>
      )}
    </Box>
  )
}

BalanceCard.propTypes = balanceCardPropTypes

export default BalanceCard
