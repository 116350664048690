import PropTypes from 'prop-types'
import { Box, IconButton, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import * as Icons from '../../../styleguide/icons'
import Stepper from './Stepper'
import styles from './modal.styles.js'

const modalPropTypes = {
  mediaLogoUrl: PropTypes.string,
  background: PropTypes.oneOf(['solid', 'gradient', 'dark']),
  currentStep: PropTypes.number,
  stepperStepsLenght: PropTypes.number,
  caption: PropTypes.func,
  title: PropTypes.func.isRequired,
  description: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node,
  renderFooter: PropTypes.func,
  sx: PropTypes.object,
}

const Modal = ({
  background = 'solid',
  currentStep,
  stepperStepsLenght = 3,
  caption,
  title,
  description,
  onCancel,
  children,
  renderFooter,
  sx,
}) => {
  const texts = useTexts()

  return (
    <Box
      sx={{ ...styles.root, ...styles[background] }}
      aria-labelledby='dialogTitle'
    >
      <Box sx={styles.content}>
        <Box sx={styles.header}>
          <Box sx={styles.headerContent}>
            {currentStep !== undefined ? (
              <Stepper
                currentStepIndex={currentStep}
                stepsLength={stepperStepsLenght}
              />
            ) : null}
            {Boolean(caption) ? (
              <Typography component='h2' sx={styles.text}>
                {caption(texts)}
              </Typography>
            ) : null}
            <Typography id='dialogTitle' sx={styles.heading}>
              {title(texts)}
            </Typography>
            {description !== undefined ? (
              <Typography sx={styles.description}>
                {description(texts)}
              </Typography>
            ) : null}
          </Box>
          {Boolean(onCancel) && (
            <Box sx={styles.closeButton}>
              <IconButton
                aria-label={texts.getCloseAction()}
                onClick={onCancel}
              >
                <Icons.Close />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box sx={{ ...styles.body, ...sx }}>{children}</Box>
      </Box>
      {Boolean(renderFooter) ? (
        <Box sx={styles.footer}>{renderFooter()}</Box>
      ) : null}
    </Box>
  )
}

Modal.propTypes = modalPropTypes

export default Modal
